import React from 'react'
import { withRBAC } from 'react-simple-rbac'

const PositionData =({ latitude , longitude }: any) => {
       return <>
      <a style={{color: "#2058A8", textDecoration: "none"}} target="_blank" href={`https://maps.google.com/maps?q=${latitude+','+longitude}`} rel="noopener noreferrer" data-cy="CustomerVehicleComponentPostionDataColumn-cy" >
       {latitude + ', '+ longitude}
       </a>
       </>
  }

export default withRBAC(PositionData, {
  requiredRoles: ["Admin","GTS", "LoTH", "FQ"],
  oneOf: true
});