import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { exportCustomerVehiclesToExcel } from "../../../helpers/CustomerVehiclesInfoServices";

type ExcelExportProps = {
  data: Array<simplifiedCustomerVehicleInfo>
  children?: ReactNode
  disabled: boolean
  slot?:string
}

const CustomerVehicleExcelExportButton = ({ slot, data, children, disabled }: ExcelExportProps) => {

  const { t } = useTranslation();
 
  return <button type="button" slot={slot} className='btn-export sdds-btn sdds-btn-primary sdds-btn-sm' onClick={() => exportCustomerVehiclesToExcel(data,t)} 
          disabled={disabled}>{children && children}</button>;
}

export default CustomerVehicleExcelExportButton;
