import { t } from 'i18next';
import './CustomerTour.scss'

const generateTourStepsByRole = (userRole: string): any => {

    const customerOverviewLabel: string = t("TS_core:CustomerOverview");
    const customerVehicleLabel: string = t("TS_core:CustomerVehicles");
    const monitoringReportRecipientsWebLabel: string = t("TS_core:MonitoringReportRecipientsWeb");
    const feedbackLabel: string = t("TS_core:SendFeedback");
    const customerOverviewDesciption: string = t("TS_core:W_CustomerOverview");
    const customerVehicleDesciption: string = t("TS_core:W_CustomerVehicles");
    const monitoringReportRecipientsWebDesciption: string = t("TS_core:W_MonitoringReport");
    const feedbackDescription: string = t("TS_core:W_Feedback");

    if(userRole === "BasicLimited")
    {
        return([
            {
                title:<h4 className='tourTitle'>{customerOverviewLabel}</h4>,
                target:"#customerOverview",
                content:<p className ='pageTour'>{customerOverviewDesciption}</p>,
                disableBeacon: true,
                placement: "right-start" as const
            },
            {
                title:<h4 className='tourTitle'>{feedbackLabel}</h4>,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
    else{
        return([
            {
                title:<h4 className='tourTitle'>{customerOverviewLabel}</h4>,
                target:"#customerOverview",
                content:<p className ='pageTour'>{customerOverviewDesciption}</p>,
                disableBeacon: true,
                placement: "right-start" as const
            },
            {
                title:<h4 className='tourTitle'>{customerVehicleLabel}</h4>,
                target:"#customer-vehicles_tour_id",
                content:<p className ='pageTour'>{customerVehicleDesciption}</p>,
            },
            {
                title:<h4 className='tourTitle'>{monitoringReportRecipientsWebLabel}</h4>,
                target:"#MonitoringReportRecipients_tour_id",
                content:<p className ='pageTour'>{monitoringReportRecipientsWebDesciption}</p>
            },
            {
                title:<h4 className='tourTitle'>{feedbackLabel}</h4>,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
}

export default generateTourStepsByRole;

export {
    generateTourStepsByRole
};
