import { AxiosPromise } from 'axios';
import { csttFacadeApi } from '../csttFacadeApi';

const getSubscriptionInfo = (customerReference: string) : AxiosPromise<SubscriptionInfoDetailsType[]> => csttFacadeApi.get(`/csttfacade/subscriptions/v1/customer/${customerReference}/staffsubscriptions`);

const terminateSubscription = (customerReference: string, staffReference: string) : AxiosPromise<any> => csttFacadeApi.put(`/csttfacade/subscriptions/v1/customer/${customerReference}/staff/${staffReference}/terminate/monitoringrecipient`);

const addSubscription = (staffReference: string) : AxiosPromise<any> => csttFacadeApi.post(`/csttfacade/subscriptions/v1/staff/${staffReference}/monitoringrecipient`);

// const getSubscriptionStatus = (staffReference: string) : AxiosPromise<boolean> => csttFacadeApi.get(`/csttfacade/subscriptions/v2/staff/${staffReference}/monitoringreport/status`);

const notifyCustomerOfMonitoringReportRecipientsChange = (customerReference: string, staffEmail: string, wasAdded: boolean) : AxiosPromise<any> => csttFacadeApi.post(`/csttfacade/subscriptions/v1/customer/${customerReference}/notifycustomer?staffEmail=${staffEmail}&wasAdded=${wasAdded}`);

const getMonitoringReportStatus = (staffReference: string) : AxiosPromise<boolean> => csttFacadeApi.get(`/csttfacade/subscriptions/v2/staff/${staffReference}/monitoringreport/status`);


export {
    getSubscriptionInfo,
    terminateSubscription,
    addSubscription,
    // getSubscriptionStatus,
    notifyCustomerOfMonitoringReportRecipientsChange,
    getMonitoringReportStatus 
}