import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataCell, DataRow, DataTable, Spinner } from '@cd/sdds-common-components-react';
import './MonitorReportDataTable.scss';
import { updateStatus, updateSubscription } from '../../redux/store/subscription';
import ConfirmationDialog from '../ConfirmationDialog';
import { addSubscription, notifyCustomerOfMonitoringReportRecipientsChange, terminateSubscription } from '../../adapters/SubscriptionInfoAdapter';
import { showToast } from '../../redux/store/toastStore';

type SubscriptionStatusType = {
  filterByActiveSubscriptions: boolean;
  onSortData?: (columnId: string, sortDirection: string) => void
  onSearch?: (searchString: string) => void
  customerMonitorReportData: Array<any> 
};

const MonitorReportDataTable = ({ filterByActiveSubscriptions, onSearch, onSortData, customerMonitorReportData }: SubscriptionStatusType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  
  // All subscriptions
  const { subscription }: any = useSelector(state => state)
  const [showAddModalDialog, setShowAddModalDialog] = useState<boolean>(false);
  const [showDeleteModalDialog, setShowDeleteModalDialog] = useState<boolean>(false);
  // The current user to change subscription for
  const [selectedSubscriber, setSelectedSubscriber] = useState<SubscriptionInfoDetailsType>();

  const DELETE = 'delete';
  const ADD = 'add';

  const columns = [
    { columnid: 'fullName', title: t('TS_core:Name'), sortable: true },
    { columnid: 'email', title: t('TS_core:EmailAddress'), sortable: true },
    { columnid: 'action', title: '', sortable: false }
  ]

  const onAddRecipientClick = (recipient: SubscriptionInfoDetailsType) => {
    setSelectedSubscriber(recipient);
    setShowAddModalDialog(true);
  }

  const onDeleteClick = (recipient: SubscriptionInfoDetailsType) => {
    setSelectedSubscriber(recipient);
    setShowDeleteModalDialog(true);
  }

  // Helper function to find a user
  const getRecipient = (externalStaffReference: string) => {
    var index = customerMonitorReportData.findIndex((s: any) => { return s.externalStaffReference === externalStaffReference });
    if (index < 0)
      return;

    var thisRecipient = customerMonitorReportData.at(index);
    if (thisRecipient === undefined)
      return;

    return thisRecipient;
  }
 
  // Helper function to update update status
  const dispatchUpdateStatus = (isUpdating: boolean, externalStaffReference: string) => {
    let updatedStatus: UpdateStatus = {
      externalStaffReference: externalStaffReference,
      isUpdating: isUpdating
    }
    dispatch(updateStatus(updatedStatus));
  }

  // Helper function to update subscription
  const dispatchSubscriptionStatus = (hasSubscription: boolean, externalStaffReference: string) => {
   let updateSubscriptionData: UpdateSubscriptionData = {
      externalStaffReference: externalStaffReference,
      hasSubscription: hasSubscription
    }
    dispatch(updateSubscription(updateSubscriptionData));
  }

  // Add subscription
  const onAddSubscription = async () => {
    // Hide modal popup
    setShowAddModalDialog(false);

    if (selectedSubscriber === undefined) {
      return;
    }
    var thisRecipient = getRecipient(selectedSubscriber.externalStaffReference);
    if (thisRecipient === undefined) {
      return;
    }

    // Start updating
    dispatchUpdateStatus(true, thisRecipient.externalStaffReference);
    await addSubscription(thisRecipient.externalStaffReference).then((res) => {
      dispatchSubscriptionStatus(true, thisRecipient.externalStaffReference);
      notifyCustomerOfMonitoringReportRecipientsChange(thisRecipient.customer.externalCustomerReference, thisRecipient.email, true);              
      dispatch(showToast({title: t('TS_core:RecipientIsRegistered'), text: thisRecipient.fullName, type: 'success'}));
    }).catch((e:any) => {
      dispatch(showToast({title: t('TS_core:FailToRegisterRecipient'), text: thisRecipient.fullName, type: 'error'}));
    }).finally(() => {
      dispatchUpdateStatus(false, thisRecipient.externalStaffReference);
    });
  }


  // Terminate subscription
  const onDeleteSubscription = async () => {
    // Hide modal popup
    setShowDeleteModalDialog(false);

    if (selectedSubscriber === undefined) {
      return;
    }
    var thisRecipient = getRecipient(selectedSubscriber.externalStaffReference);
    if (thisRecipient === undefined) {
      return;
    }

    // Start updating
    dispatchUpdateStatus(true, thisRecipient.externalStaffReference); 
    await terminateSubscription(thisRecipient.customer.externalCustomerReference, thisRecipient.externalStaffReference).then((res) => {    
      dispatchSubscriptionStatus(false, thisRecipient.externalStaffReference);
      notifyCustomerOfMonitoringReportRecipientsChange(thisRecipient.customer.externalCustomerReference, thisRecipient.email, true);        
      dispatch(showToast({title: t('TS_core:RecipientIsDeregistered') , text: thisRecipient.fullName, type: 'success'}));
    }).catch((e:any) => {
      dispatch(showToast({title: t('TS_core:FailToDeregisterRecipient') , text: thisRecipient.fullName, type: 'error'}));
    }).finally(() => {
      dispatchUpdateStatus(false, thisRecipient.externalStaffReference);
    });  
  }
  

  const DataRowElement = (recipient: SubscriptionInfoDetailsType, action: string) => {
    return <>
      <DataRow key={recipient.externalStaffReference}>
        {columns.map((col) => {
          switch (col.columnid) {
            case 'fullName':
              return (
                <DataCell>
                  <span>{recipient.fullName}</span>
                </DataCell>
              )
            case 'email':
              return (
                <DataCell>
                  {recipient.email}
                </DataCell>
              )
            case 'action':
              return (
                <DataCell>
                  {action === DELETE
                    ?<span className='delete-spinner'>
                      <>
                        { (recipient.isUpdating)
                          ? <Spinner size='sm' />
                          : <button disabled={recipient.email === null} className='sdds-btn sdds-btn-danger sdds-btn-sm' onClick={() => onDeleteClick(recipient)}>{t('TS_core:Deregister')}</button>}
                      </>
                    </span>
                    : <span className='delete-spinner'>
                      <>
                        {recipient.isUpdating
                          ? <Spinner size='sm' />
                          : <button disabled={recipient.email === null} className='sdds-btn sdds-btn-primary sdds-btn-sm' onClick={() => onAddRecipientClick(recipient)}>{t('TS_core:RegisterNewRecipient')}</button>}
                      </>
                    </span>
                  }

                </DataCell>
              )
            default:
              return <></>
          }
        })}
      </DataRow>
    </>
  }

  return (
    <>
      {showAddModalDialog &&
        <ConfirmationDialog
          isDelete={false}
          onClose={() => { setShowAddModalDialog(false); }}
          onAction={onAddSubscription}
          title={t('TS_core:RegisterNewRecipient')}
          confirmationMsg={t('TS_core:ConfirmRegisterRecipient')}
          primaryActionBtn={t('TS_core:Register')} 
          secondaryActionBtn={t('TS_core:Cancel')} 
          name={selectedSubscriber?.firstName + ' ' + selectedSubscriber?.lastName}
          email={selectedSubscriber?.email ? selectedSubscriber?.email : ''}
        />
      }
      {showDeleteModalDialog &&
        <ConfirmationDialog
          isDelete={true}
          onClose={() => { setShowDeleteModalDialog(false) }}
          onAction={onDeleteSubscription}
          title={t('TS_core:DeregisterRecipient')}
          confirmationMsg={t('TS_core:DeregisterConfirmMessage')}
          primaryActionBtn={t('TS_core:Deregister')}
          secondaryActionBtn={t('TS_core:Cancel')}
          name={selectedSubscriber?.firstName + ' ' + selectedSubscriber?.lastName}
          email={selectedSubscriber?.email ? selectedSubscriber?.email : ''}
        />
      }
      {subscription.isLoading && <div className='loader'><Spinner size='lg'/></div>}
      <DataTable
        columns={columns}
        title={`${t("")}`}
        onSearchChange={onSearch}
        onSort={onSortData} >
        <>
          {customerMonitorReportData &&
            <>
              {filterByActiveSubscriptions ?
                customerMonitorReportData.map((recipient: SubscriptionInfoDetailsType) => {
                  return DataRowElement(recipient, DELETE)
                }) :
                customerMonitorReportData.map((recipient: SubscriptionInfoDetailsType) => {
                  return DataRowElement(recipient, ADD)
                })
              }
            </>
          }
        </>
      </DataTable>
    </>
  )
};

export default MonitorReportDataTable;
