import { createSlice } from '@reduxjs/toolkit'
import { getCustomerVehicles, getCustomerVehiclesInfo } from '../../adapters/CustomerVehiclesInfo';
import { unitConvertor } from '../../helpers/unitConverter';
import { utcFormatFromString } from '../../helpers/timeFormatter';

const getInitialState = (): CustomerVehiclesStateType => {
  const State: CustomerVehiclesStateType = {
    isLoading: false,
    hasError: false,
    errorMessage: "",
    customerVehicleReferenseList: [],
    customerVehicleInfoList: [],
    percentage: 0,
    isVehiclesInfoListLoading: false,
    numberOfVehiclesCount: 0,
    searchCustomerReference: ""
  }
  return State;
}

// Slice
const slice = createSlice({
  name: 'customerVehiclesInfo',
  initialState: getInitialState(),
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    getCustomerVehicleReferencesData: (state, action) => {
      state.customerVehicleReferenseList = action.payload;
    },
    getCustomerVehiclesInformationData: (state, action) => {
      state.customerVehicleInfoList = action.payload;
    },
    setVehiclesInfoListPercentage: (state, action) => {
      state.percentage = action.payload
    },
    setNumberofVehiclesCount: (state, action) => {
      state.numberOfVehiclesCount = action.payload
    },
    setSearchCustomerReference: (state, action) => {
      state.searchCustomerReference = action.payload
    },
    setIsVehiclesInfoListLoading: (state, action) => {
      state.isVehiclesInfoListLoading = action.payload
    },
    updateError: (state, action) => {
      state.hasError = action.payload !== '';
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
  },
});
export default slice.reducer

// Actions
const { setIsLoading, getCustomerVehicleReferencesData, setVehiclesInfoListPercentage, setIsVehiclesInfoListLoading, getCustomerVehiclesInformationData, setNumberofVehiclesCount, setSearchCustomerReference, updateError } = slice.actions

export const loadCustomerVehicleReferencesData = (externalCustomerReference: string, unitType: string) => async (dispatch: any) => {
  try{
    dispatch(setIsLoading(true));
    dispatch(setSearchCustomerReference(externalCustomerReference));
    let customerVehiclesReferensesResponseType = await getCustomerVehicles(externalCustomerReference);
    let customerVehiclesReferensesResponse = customerVehiclesReferensesResponseType.data;
    dispatch(getCustomerVehicleReferencesData(customerVehiclesReferensesResponse));
    dispatch(setNumberofVehiclesCount(customerVehiclesReferensesResponse.length));
    if(customerVehiclesReferensesResponse.length > 0){
      dispatch(getVehicleBatchList(customerVehiclesReferensesResponse, unitType))
    }
    dispatch(setIsLoading(false));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const getVehicleBatchList = (customerReferenceList: string[], unitType: string) => async (dispatch: any, getState: any) => {
  try{
    dispatch(setIsVehiclesInfoListLoading(true));
    let {customerVehiclesInfoStore} = getState();
    const numBatches = Math.ceil(customerReferenceList.length / 5);
    let appendingVehiclesInfoListData = [...customerVehiclesInfoStore.customerVehicleInfoList]
    for(let i= 0; i < numBatches; i++){
      const startIndex = i*5;
      const endIndex = startIndex + 5;
      const referenceBatchList = customerReferenceList.slice(startIndex, endIndex);
      let vehiclesInfoBatchResponseType = await getCustomerVehiclesInfo(referenceBatchList);
      let vehicleBatchDataResponse = vehiclesInfoBatchResponseType.data.equipments.map((vehicle: any) =>{
        return {
          externalEquipmentReference: vehicle.equipment?.externalEquipmentReference,
          identification: vehicle.equipment?.identification,
          chassisNumber: vehicle.equipment?.chassisNumber,
          registrationNumber: vehicle.equipment?.registrationNumber,
          alias: vehicle.equipment?.alias,
          communicatorType: vehicle.communicator?.type,
          communicatorVersion : vehicle.communicator?.version,
          latitude: vehicle.latestStatus?.position?.latitude,
          longitude: vehicle.latestStatus?.position?.longitude,
          positionTime: vehicle.latestStatus?.position?.time ? utcFormatFromString(vehicle.latestStatus?.position?.time) : '',
          odometer: vehicle?.latestStatus?.odometerInMeters,
          convertedOdometer : (vehicle?.latestStatus?.odometerInMeters) ? (unitConvertor(vehicle?.latestStatus?.odometerInMeters, 'metre', unitType)) : '',
          messageTime: vehicle.latestStatus?.timeMessage ? utcFormatFromString(vehicle.latestStatus?.timeMessage): '',
          package:  vehicle?.package,
          commaSeparatedPackage : vehicle?.package.map((item: any)=> `${item.packageName}(${utcFormatFromString(item.timeStart)}${item?.timeStop ? '-'+utcFormatFromString(item.timeStop) : '' })`)
        }
       });
      appendingVehiclesInfoListData = appendingVehiclesInfoListData.concat(vehicleBatchDataResponse);
      dispatch(getCustomerVehiclesInformationData(appendingVehiclesInfoListData));
      const percentageByBatch = Math.ceil(((i+1)/ numBatches) * 100);
      dispatch(setVehiclesInfoListPercentage(percentageByBatch))
    }
    dispatch(setIsVehiclesInfoListLoading(false));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const clearCustomerVehicleData = () => async (dispatch: any) => {
  try {
    dispatch(getCustomerVehiclesInformationData([]));
    dispatch(getCustomerVehicleReferencesData([]));
    dispatch(setNumberofVehiclesCount(0));
    dispatch(setVehiclesInfoListPercentage(0));
    
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const sortCustomerVehicleDataList = (sortedMessageData: Array<simplifiedCustomerVehicleInfo>) => async (dispatch: any) => {
  try {
    dispatch(getCustomerVehiclesInformationData(sortedMessageData));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}