
const microServiceRedirector = (baseUrl: any, currentAppName: string, redirectAppName: string): string  => {

    if(baseUrl){
        if(baseUrl.includes('localhost')){
            return `https://${redirectAppName}.ts.test.cs.scania.com/`
         }else{
             return baseUrl.replace(currentAppName, redirectAppName)
         }
    }else return '';
}

export {
    microServiceRedirector
}