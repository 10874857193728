/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState} from 'react';
import { useDispatch } from "react-redux";
import { useAppSelector } from '../../../redux/store';
// import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { fetchSubscriptionInfo, sortCustomerReport } from '../../../redux/store/subscription';
import MonitorReportDataTable from '../../../components/MonitorReportDataTable';
import './MonitoringReport.scss';
import { Icons } from '@cd/sdds-common-components-react';
import { useTranslation } from 'react-i18next';

const MonitoringReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { selectedCustomer } = useAppSelector<SearchState>(state => state.universalSearch)
  const { subscriptionData, searchCustomerReference } = useAppSelector<SubscriptionStateType>(state => state.subscription);
  const [activeSubscribers, setActiveSubscribers] = useState<Array<SubscriptionInfoDetailsType>>(subscriptionData);
  const [inactiveSubscribers, setInactiveSubscribers] = useState<Array<SubscriptionInfoDetailsType>>(subscriptionData);


  useEffect(() => {
    if(searchCustomerReference !== selectedCustomer.externalCustomerReference){
    dispatch(fetchSubscriptionInfo(selectedCustomer.externalCustomerReference)); 
  }  
  }, [selectedCustomer]);

  useEffect(() => {
    if (subscriptionData && subscriptionData.length > 0) {
      setActiveSubscribers(getRecipientsBySubscriptionStatus(subscriptionData, true));
      setInactiveSubscribers(getRecipientsBySubscriptionStatus(subscriptionData, false));
    }
  }, [subscriptionData]);

  const getRecipientsBySubscriptionStatus = (reportRecipients: SubscriptionInfoDetailsType[], showOnlyActiveSubscriptions: boolean) => {
    return reportRecipients.filter((recipient) => {
      if (showOnlyActiveSubscriptions)
        return recipient.hasMonitoringReportSubscription;
        
      return recipient.hasMonitoringReportSubscription === false
    });
  };

  const onSortData = (columnId: string, sortDirection: string) => {
    if (subscriptionData && subscriptionData.length > 0) {
      let sortedResults = _.sortBy(subscriptionData, columnId);
      if (sortDirection === 'desc') {
        sortedResults = _.reverse(sortedResults);
      }
      dispatch(sortCustomerReport(sortedResults as Array<SubscriptionInfoDetailsType>));
    }
  }

  const searchFilter = (searchString: string, subscribers: Array<SubscriptionInfoDetailsType>) => {
    const splittedString = searchString.split(",");
    let filteredDataArray;
    if (splittedString && splittedString.length > 0) {
      filteredDataArray = splittedString.map((searchstr: string) => {
        searchstr = searchstr.trim();
        if (searchstr === "") return [];
        return subscribers.filter(a => _.values(a).some((b: any) => {
          if (typeof b === 'string') {
            return b?.toLocaleLowerCase().includes(searchstr.toLocaleLowerCase())
          } else {
            return b?.toString().toLocaleLowerCase() === searchstr.toLocaleLowerCase()
          }
        }
        ));
      })
      return filteredDataArray;
    }
  }

  const onSearchInactiveSubscribers = (searchString: string) => {
    if (searchString.length > 0) {
      // Restore the data
      const inactiveSubscribers = getRecipientsBySubscriptionStatus(subscriptionData, false);
      
      if (inactiveSubscribers && inactiveSubscribers.length > 0) {
        let filteredDataArray = searchFilter(searchString, inactiveSubscribers);
        setInactiveSubscribers(_.flatten(filteredDataArray) as Array<SubscriptionInfoDetailsType>);
        return false;
      }
    } else {
      setInactiveSubscribers(getRecipientsBySubscriptionStatus(subscriptionData, false));
    }
  }

  const onSearchActiveSubscribers = (searchString: string) => {
    if (searchString.length > 0) {
      // Restore the data
      const activeSubscribers = getRecipientsBySubscriptionStatus(subscriptionData, true);

      if (activeSubscribers && activeSubscribers.length > 0) {
        let filteredDataArray = searchFilter(searchString, activeSubscribers);
        setActiveSubscribers(_.flatten(filteredDataArray) as Array<SubscriptionInfoDetailsType>);
        return false;
      }
    } else {
      setActiveSubscribers(getRecipientsBySubscriptionStatus(subscriptionData, true));
    }
  }
  return <>
   { selectedCustomer && selectedCustomer?.scaniaLegalDocumentationSigned && selectedCustomer?.scaniaRead &&
          <div className="monitoring-report-container sdds-container-fluid">    
          <div className='sdds-headline-05'></div>
              <div className='disclaimer'><Icons.InfoIcon /><div className='sdds-body-02'>{t("TS_core:CustomerEmailNotificationWhenChangingRecipientList")}</div></div>
              <sdds-inline-tabs id="subscriptionsTabs">
                <div data-default="true" data-name={t("TS_core:ActiveSubscriptions")} tab-key="ActiveSubscriptions">
                  <div className="dt-row sdds-row">
                    <div className='dt-col sdds-no-padding sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 '>
                      <div className='dt-container'>
                        <MonitorReportDataTable customerMonitorReportData={activeSubscribers} filterByActiveSubscriptions={true} onSortData={onSortData} onSearch={onSearchActiveSubscribers}  />
                        </div>
                    </div>
                  </div>
                </div>
                <div data-name={t("TS_core:StaffWithoutSubscriptions")} tab-key="StaffWithoutSubscriptions">
                  <div className="dt-row sdds-row">
                    <div className='dt-col sdds-no-padding sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 '>
                      <div className='dt-container'>
                      <MonitorReportDataTable customerMonitorReportData={inactiveSubscribers} filterByActiveSubscriptions={false} onSortData={onSortData} onSearch={onSearchInactiveSubscribers} />
                      </div>
                    </div>
                  </div>
                </div>
              </sdds-inline-tabs>
            </div>
}
        </>
}

export default MonitoringReport;
