import React, { useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import './CustomerVehicles.scss';
import { clearCustomerVehicleData, loadCustomerVehicleReferencesData, sortCustomerVehicleDataList } from '../../../redux/store/CustomerVehicleInfo';
import CustomerVehiclesDataTable from '../../../components/CustomerVehicles/CustomerVehiclesDataTables';
import _ from 'lodash';
import { UserContext } from '../../../appContext';
import {LocalizationHelper} from '@cd/sdds-common-components-react';


const CustomerVehicles = () => {
  const dispatch = useAppDispatch();
  const { customerVehicleInfoList, numberOfVehiclesCount, searchCustomerReference } = useAppSelector<CustomerVehiclesStateType>(state => state.customerVehiclesInfoStore);
  const { selectedCustomer } = useAppSelector<SearchState>(state => state.universalSearch)
  const [searchResults, setSearchResults] = useState<Array<simplifiedCustomerVehicleInfo>>(customerVehicleInfoList);
  const { settings } = useContext(UserContext);
  const [unitType, setUnitType] = useState('Metric');
  const { t } = LocalizationHelper.useLocalize();

  useEffect(() => {
    setUnitType(settings?.unit)
  }, [settings]);

  useEffect(() => {
    if (customerVehicleInfoList && customerVehicleInfoList.length > 0) {
      setSearchResults(customerVehicleInfoList);
    }
  }, [customerVehicleInfoList]);

  useEffect(() => {
    if(searchCustomerReference !== selectedCustomer.externalCustomerReference){
      clearCustomerStoreData();
        //Due to univesal search issue adding below condition
        if (selectedCustomer && selectedCustomer.externalCustomerReference !== '00000000-0000-0000-0000-000000000000') {
          dispatch(loadCustomerVehicleReferencesData(selectedCustomer.externalCustomerReference, unitType));
        }
    }
  }, [selectedCustomer])


  const handleCustomerSort = (columnId: string, sortDirection: string) => {
    if (customerVehicleInfoList && customerVehicleInfoList.length > 0) {
      if (columnId === 'convertedOdometer') {
        columnId = 'odometer'
      }
      let sortedResults = _.sortBy(customerVehicleInfoList, columnId);
      if (sortDirection === 'desc') {
        sortedResults = _.reverse(sortedResults);
      }
      dispatch(sortCustomerVehicleDataList(sortedResults as Array<simplifiedCustomerVehicleInfo>));
    }
  }

  const clearCustomerStoreData = () => {
    dispatch(clearCustomerVehicleData());
    setSearchResults([])
  }


  const handleCustomerSearch = (searchString: string) => {
    if (searchString.length > 0) {
      if (customerVehicleInfoList && customerVehicleInfoList.length > 0) {
        const splitedString = searchString.split(",");
        let filteredDataArray;
        if (splitedString && splitedString.length > 0) {
          filteredDataArray = splitedString.map((searchstr: string) => {
            searchstr = searchstr.trim();
            if (searchstr === "") return [];
            return customerVehicleInfoList.filter(a => _.values(a).some((b: any) => {
              if (typeof b === 'string') {
                return b?.toLocaleLowerCase().includes(searchstr.toLocaleLowerCase())
              } else if (typeof b === 'object') {
                return JSON.stringify(b).toLocaleLowerCase().includes(searchstr.toLocaleLowerCase())
              } else {
                return b?.toString().toLocaleLowerCase() === searchstr.toLocaleLowerCase()
              }
            }
            ));
          })
        }

        setSearchResults(_.flatten(filteredDataArray) as Array<simplifiedCustomerVehicleInfo>);
        return false;
      }
    } else {
      setSearchResults(customerVehicleInfoList);
    }
  }

  return <>
    { selectedCustomer && selectedCustomer?.scaniaLegalDocumentationSigned && selectedCustomer?.scaniaRead &&
      <div className="customer-vehicles-container sdds-container-fluid">
        <div className='sdds-headline-04' data-cy="CustomerVehiclesPageNumberOfCustomerVehicles-cy">{t("TS_core:NumberOfEquipments", [numberOfVehiclesCount.toString()])}</div>
        <div className='dt-row sdds-u-mt2'></div>
        <div className="dt-row sdds-row">
          <div className='dt-col sdds-no-padding sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 '>
            <div className='dt-container'>
              <CustomerVehiclesDataTable customerVehiclesData={searchResults} onSearch={handleCustomerSearch} onSortData={handleCustomerSort} />
            </div>
          </div>
        </div>
      </div>
    }
  </>
}

export default CustomerVehicles;
