import React, { useState } from 'react';
import ReactJoyride from 'react-joyride';
import { useTranslation } from 'react-i18next';
import { generateTourStepsByRole } from '../CustomerTour/TourHelprs'
import './CustomerTour.scss'

type HomepageProps = {
    loggedInProfileInfo: ProfileInformationType,
  }

const CustomerTour=({ loggedInProfileInfo }: HomepageProps)=> {
    const { t } = useTranslation();
    const [start, SetStart]=useState(false)
    const userRole = loggedInProfileInfo.role;

        return(
        <>
            <button className='sdds-nav__dropdown-item-core' onClick={() => SetStart(!start)} >{t("TS_core:Customer")}</button>
            <ReactJoyride steps = {generateTourStepsByRole(userRole)}
                run={start}
                continuous={true}
                showSkipButton={true}
                spotlightClicks={true}
                hideBackButton={false}
                hideCloseButton={true}
                disableScrolling={true}
                locale={
                    {
                        next: t("TS_core:Next"),
                        skip: t("TS_core:Skip"),
                        back: t("TS_core:Back"),
                        last: t("TS_core:Done")

                    }
                }
                styles={
                {
                    options: {
                        arrowColor:"#16417F",
                        backgroundColor: "white",
                        primaryColor: "#16417F",
                        overlayColor: "transparent",
                        beaconSize: 70
                    },
                    spotlight: {
                        backgroundColor: "rgba(25, 110, 200, .2)"
                    },
                    buttonSkip: {
                        color: "#2058A8"
                    }
                }
            }
            />
        </>
        );
}

export default CustomerTour;