import { TFunction } from "i18next";
import { exportExcelUtcFormatFromString } from "./timeFormatter";
import alasql from "alasql";

const getColumnsConfig = (t: TFunction) => {
  return {
    headers: true,
    column: {
      style: 'font-size:16px'
    },
    columns: [
      { columnid: 'identification', title: t('TS_core:Vin') +', '+ t('TS_core:ChassisNumber'), sortable: false },
      { columnid: 'registrationNumber', title: t('TS_core:RegistrationNumber'), sortable: true },
      { columnid: 'alias', title: t( 'TS_core:Alias'), sortable: true },
      { columnid: 'communicatorType', title: t('TS_core:Communicator'), sortable: true },
      { columnid: 'communicatorVersion', title: t("TS_core:Version"), sortable: true },
      { columnid: 'packageName', title: t("TS_core:Subscriptions"), sortable: false },
      { columnid: 'convertedOdometer', title: t('TS_core:TS_Odometer'), sortable: true },
      { columnid: 'position', title: t('TS_core:Location'), sortable: false }
    ]
  };
}

const getExportExcelColumnsConfig = (t: TFunction) => {
  return {
    headers: true,
    column: {
      style: 'font-size:16px'
    },
    columns: [
      { columnid: 'externalEquipmentReference', title: t('External equipment reference'), sortable: false },
      { columnid: 'identification', title: t('TS_core:Vin'), sortable: false },
      { columnid: 'registrationNumber', title: t('TS_core:RegistrationNumber'), sortable: true },
      { columnid: 'chassisNumber', title: t('TS_core:ChassisNumber'), sortable: false },
      { columnid: 'alias', title: t( 'TS_core:Alias'), sortable: true },
      { columnid: 'communicatorType', title: `${t("TS_core:CommunicatorModelCheck")}: ${t("TS_core:CommunicatorModel")}`, sortable: true },
      { columnid: 'communicatorVersion', title:  `${t("TS_core:CommunicatorModelCheck")}: ${t("TS_core:Version")}`, sortable: true },
      { columnid: 'longitude', title: `${t("TS_core:LastReceivedStatusData")}: ${t("TS_core:Longitude")}`, sortable: false },
      { columnid: 'latitude', title: `${t("TS_core:LastReceivedStatusData")}: ${t("TS_core:Latitude")}`, sortable: false },
      { columnid: 'positionTime', title: `${t("TS_core:LastReceivedStatusData")}: ${t("TS_core:PositionTime")}`, sortable: false },
      { columnid: 'convertedOdometer', title: `${t("TS_core:LastReceivedStatusData")}: ${t("TS_core:TS_Odometer")}`, sortable: false },
      { columnid: 'messageTime', title: `${t("TS_core:LastReceivedStatusData")}: ${t("TS_core:MessageTime")}`, sortable: false },
      { columnid: 'commaSeparatedPackage', title: t("TS_core:Subscriptions"), sortable: false },
   
    ]
  };
}

const exportCustomerVehiclesToExcel = (customerVehiclesData: Array<simplifiedCustomerVehicleInfo>, t: TFunction) => {
  const config = getExportExcelColumnsConfig(t);
  const timestamp =exportExcelUtcFormatFromString('now')
  const filename = ["Customer_Vehicles_Info", timestamp,].join('_') + '_UTC.xls';
  return alasql.promise('SELECT * INTO XLS("' + filename + '", ?) FROM ?', [config, customerVehiclesData]);
}

export {
  exportCustomerVehiclesToExcel,
  getColumnsConfig
}