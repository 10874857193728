import { createSlice } from '@reduxjs/toolkit'
import { getSubscriptionInfo } from '../../adapters/SubscriptionInfoAdapter';


const getInitialState = (): SubscriptionStateType => {
    const state: SubscriptionStateType = {
        isLoading: false,
        hasError: false,
        errorMessage: '',
        searchCustomerReference: "",
        subscriptionData:[ {
            externalStaffReference: '',
            firstName: '',
            lastName: '',
            fullName: '',
            login: undefined,
            email: '',
            language: '',
            roles: undefined,
            isUpdating: false,
            hasMonitoringReportSubscription: false,
            customer: {
                externalCustomerReference: '',
                name: '',
                country: '',
                vatNumber: '',
                language: '',
                scaniaRead: false,
                scaniaLegalDocumentationSigned: false,
                contactInformation: null
            }
        }]
    }
    return state;
  }

// Slice
const slice = createSlice({
    name: 'subscription',
    initialState: getInitialState(),
    reducers: {
        updateIsLoading: (state, action) => {
            state.isLoading = action.payload;  
        },
        updateErrorMessage: (state, action) => {
            state.errorMessage = action.payload
        },
        updateSubscriptionInfo: (state, action) => {
            state.subscriptionData = action.payload
        },
        updateSubscriptionStatus: (state, action) => {
            const newArray = [...state.subscriptionData];
            const index = newArray.findIndex(subscriber => subscriber.externalStaffReference === action.payload.externalStaffReference);
            let tmp = newArray.splice(index, 1);
            let currentSubscriber = {...tmp[0]};
            currentSubscriber.hasMonitoringReportSubscription = action.payload.hasSubscription;            
            newArray.push(currentSubscriber);
            return { 
                ...state, //copying the orignal state
                subscriptionData: newArray, //re-assingning subscriber to new array
               }

        },
        setUpdateStatus: (state, action) => {
            const newArray = [...state.subscriptionData];
            const index = newArray.findIndex(subscriber => subscriber.externalStaffReference === action.payload.externalStaffReference);
            let tmp = newArray.splice(index, 1);
            let currentSubscriber = {...tmp[0]};
            currentSubscriber.isUpdating = action.payload.isUpdating;
            newArray.push(currentSubscriber);
            return { 
                ...state, //copying the orignal state
                subscriptionData: newArray, //re-assingning subscriber to new array
            }
        },
        setSearchCustomerReference: (state, action) => {
          state.searchCustomerReference = action.payload
        }
    },
});

export default slice.reducer

// Actions
const { updateIsLoading, updateErrorMessage, updateSubscriptionInfo, updateSubscriptionStatus, setUpdateStatus, setSearchCustomerReference} = slice.actions


export const fetchSubscriptionInfo = (externalCustomerReference: string) => async (dispatch: any) => {
    try {
        dispatch(updateIsLoading(true));
        dispatch(setSearchCustomerReference(externalCustomerReference));
        const res = await getSubscriptionInfo(externalCustomerReference)
        let updatedResponse = res.data.map(subscription=>{
            return{
                ...subscription,
                fullName: subscription.firstName+' '+ subscription.lastName,
                isUpdating: false,
                hasError: false
            }
        })
        dispatch(updateSubscriptionInfo(updatedResponse));
        dispatch(updateIsLoading(false));
    } catch (e: any) {
        dispatch(updateErrorMessage(e.message));
        dispatch(updateIsLoading(false));
        return console.error(e);
    }
}

export const updateStatus = (status: UpdateStatus) => async (dispatch: any) => {
    dispatch(setUpdateStatus(status));
}

export const updateSubscription = (status: UpdateSubscriptionData) => async (dispatch: any) => {
    dispatch(updateSubscriptionStatus(status));
}

export const sortCustomerReport = (sortedMessageData: Array<SubscriptionInfoDetailsType>) => async (dispatch: any) => {
    try {
      dispatch(updateSubscriptionInfo(sortedMessageData));
    } catch (error: any) {
      return console.error(error);
    }
  }
