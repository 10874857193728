import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { createSelectorHook, useDispatch } from 'react-redux'
import user from './user'
import subscription from './subscription'
import { CommunicatorInfoStore, CustomerInfoStore, LatestInfoStore, UniversalSearchStore } from '@cd/sdds-common-components-react'
import customerVehiclesInfo from './CustomerVehicleInfo'
import toastStore from './toastStore'

const reducer = combineReducers({
  user,
  customerVehiclesInfoStore: customerVehiclesInfo,
  latestInfo: LatestInfoStore,
  communicatorInfo: CommunicatorInfoStore,
  subscription,
  toastStore,
  universalSearch: UniversalSearchStore,
  customerInfo: CustomerInfoStore
});

const store = configureStore({
  reducer,
});

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector = createSelectorHook<RootState>();
export default store;