
  const unitConvertor = (value: number, unit: string, unitType: string): string => {
    let convertedValue: string;
    if (unitType === "Metric") {
      //#region Speed
      if(unit === 'm/s'){
        convertedValue = value ? (Math.round((value*3600/1000)) + ' km/h') : '';
      }
      else if(unit === 'mph'){
        convertedValue = value ? (Math.round((value*1.609344)) + ' km/h') : '';
      }
      //#endregion
      //#region Distance
      else if(unit === 'metre'){
        convertedValue = value ? (Math.round((value / 1000)) + ' km') : '';
      }
      //#endregion
      else {
        convertedValue='';
      }
    }
    else if (unitType === "Imperial") {
      //#region Speed
      if(unit === 'm/s'){
        convertedValue =  value ? (Math.round((value*3600/1000 * 0.621371192)) + ' mph') : '';
      }
      //#endregion
       //#region Distance
      else if(unit === 'km'){
        convertedValue = value ? (Math.round((value * 0.621371192)) + ' miles') : '';
      }
      else if(unit === 'metre'){
        convertedValue = value ? (Math.round((value * 0.000621371192)) + ' miles') : '';
      }
      //#endregion
       else {
        convertedValue='';
      }
    } 
    else {
      convertedValue='';
    }
  
    return convertedValue;
  }
  

export {
  unitConvertor
};




